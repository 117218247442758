import styled from 'styled-components'

const ErrorPageStyle = styled.div.attrs()`
	--animeMarg: var(--sp7x);
	--titleMarg: var(--sp4x);

	height: 100vh;
	padding: var(--sp5x) var(--sp9x);

	.LottieWrapp {
		padding: 15% 30%;
		margin-bottom: var(--animeMarg);
	}

	.title {
		margin-bottom: var(--titleMarg);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeM}) {
		--animeMarg: var(--sp6x);
		--titleMarg: var(--sp4x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.DesktopSizeS}) {
		--animeMarg: var(--sp5x);
		--titleMarg: var(--sp4x);
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		--animeMarg: var(--sp4x);
		--titleMarg: var(--sp3x);

		.LottieWrapp {
			padding: 20% 50%;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) =>
			props.theme.mediaQuery.MobileSize}) {
		--animeMarg: var(--sp4x);
		--titleMarg: var(--sp3x);
		.LottieWrapp {
			padding: 20% 50%;
		}
	}

	@media screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		--animeMarg: var(--sp3x);
		--titleMarg: var(--sp2x);

		padding: var(--sp2x) var(--sp4x);

		.LottieWrapp {
			padding: 25% 50%;
		}
	}
`
export default ErrorPageStyle

import React, { memo as Memo } from 'react'
import { Text, Button, LottieAnimation } from 'components/common'
import ErrorPageStyle from 'styles/pages/ErrorPageStyle'

import errorAnimation from 'lottieAnimation/404/animeError.json'

const NotFound = Memo((props) => {
	return (
		<ErrorPageStyle className='FlexBoxColumn alignMiddle alignCenter'>
			<LottieAnimation animationJSON={errorAnimation} loop={true} isStopped={false} />
			<Text tag={'h3'} className={'title MonBold black'} text={'PageNotFound'} />
			<Button link='/' className='btn btn-black' text={'BackToHome'} />
		</ErrorPageStyle>
	)
})

export default NotFound
